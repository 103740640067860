import { Typography } from '@material-ui/core'
import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import PoiDetailsTag from '../components/poiDetailsTag'
import config from '../../config.json'

type PoiDetailProps = PageProps

const title = 'Premium'
const {
  seo: {
    website: { description: websiteDescription },
  },
} = config
const PoiDetail = ({ location }: PoiDetailProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  return (
    <Layout
      location={location}
      seoProps={{
        title,
        description: websiteDescription,
      }}
    >
      <Typography component="h1" variant="h2" gutterBottom>
        {title}
      </Typography>

      <PoiDetailsTag />
    </Layout>
  )
}

export default PoiDetail
