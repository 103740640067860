import React, { forwardRef, useEffect } from 'react'

export type PoiDetailsTagProps = unknown

const PoiDetailsTag = forwardRef<HTMLDivElement, PoiDetailsTagProps>(
  (props, ref) => {
    useEffect(() => {
      if (!document.querySelector('#Services123_root')) {
        window.location.reload()
      }
    }, [])

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
        <link rel="stylesheet" media="screen" href="https://services.mairdumont.com/resources/content/baedeker/detail.css">
        <script>
  var service123_tagtype = "detailsmap";
  var service123_theme = "baedeker";
  var service123_control1 = "FalkBigMapControl,right,8,8";
        </script>
        <script src="https://services.mairdumont.com/123services/js/show_detailstag.js"></script>
      `,
        }}
        ref={ref}
      />
    )
  },
)

PoiDetailsTag.displayName = 'PoiDetailsTag'

export default PoiDetailsTag
